.Aboutus1 {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 0px 80px;
  position: relative;
}
.aboutus-container-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 120px;
  z-index: 5; */
}
.slider-carousel{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-logo{
  width: 100%;
  height: 50px;

}
.carousel-icon{
  display: flex;align-items: center;
  justify-content: center;
}
.slick-arrow .slick-prev{
  visibility: hidden;
}
.slick-arrow .slick-next{
  visibility: hidden;
}
/* .aboutus-container-1::-webkit-scrollbar {
  display: none;
} */
/* .aboutus-container-1-mobile-view::-webkit-scrollbar {
  display: none;
} */
.icon {
  transition: transform 0.3s ease;
  transform: translateY(-15px) scale(1.05);
}

.nationalicon {
  width: 242.34px;
  height: 46.77px;
}
.appoloicon {
  width: 238px;
  height: 91px;
}
.Danfoss {
  width: 220.13px;
  height: 85.38px;
}
.oerlikon {
  width: 245px;
  height: 53px;
}
.aboutus-container-2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
}
.ourclients-text {
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 50px;
  gap: 30px;
}
.ourclients-heading {
  font-family: "Noto Serif Display", serif;
  font-size: 80px;
  font-weight: 600;
  color: #ffffff;
}
.ourclients-text-content {
  max-width: 626px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b3b3b3;
}
.ourclients-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: all 0.9s linear;
  bottom: 0%;
}
.ourclients-img {
  width: 100%;
  height: 90%;
  object-fit: cover;
}
@media (max-width: 1000px) {
  .Aboutus1 {
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
    grid-template-rows: 0.5fr 1fr;
  }
  .aboutus-container-2 {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    grid-template-columns: 1fr ;
  }
  .ourclients-text {
    text-align: right;
    justify-content: center;
    padding-bottom: 0;
  }
  /* .aboutus-container-1 {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  gap: 120px;
} */
}
@media (max-width: 700px) {
  .ourclients-heading {
    font-size: 60px;
  }
  
}
@media (max-width: 550px) {
  .ourclients-heading  {
    font-size: 50px;
  }
  .nationalicon {
    width: 200.34px;
    height: 35.77px;
  }
  .appoloicon {
    width: 190px;
    height: 85px;
  }
  .Danfoss {
    width: 180.13px;
    height: 70.38px;
  }
  .oerlikon {
    width: 200px;
    height: 43px;
  }
  .aboutus-container-1-mobile-view {
    gap: 70px;
  }
}

@media (max-width: 460px) {
  .ourclients-heading {
    font-size: 45px;
  }
}
@media (max-width: 460px) {
  .ourclients-heading {
    font-size: 36px;
  }
  .ourclients-text-content  {
    font-size: 14px;
  }
}