@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* background: #ffffff; */
}
::-webkit-scrollbar {
  display: none;
}
/* .App {
text-align: center;
overflow: hidden;
} */
/* App.css */
.container {
  /* Set up your app styles here */
  cursor: none;
  background-color: #FFFFFF;
  transition: all 0.5s ease;
}

.noise::before{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "";
  opacity: .05;
  z-index: 2000;
  pointer-events: none;
  background-image: url(./Assets/noise.gif);
}
/* .horizontalscrolling {
  display: flex;
  overflow-x: hidden; /* Hide horizontal scroll bar */
  /* scroll-snap-type: x mandatory;
  scroll-behavior: smooth; */

/* } */ 




/* .horizontalscrolling {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.scroll-section {
  min-width: 100vw;
  min-height: 100vh;
  scroll-snap-align: start;
} */
/* Apply styles for each section/component inside the container */
/* For example, you can set the width of each section to fit the viewport */
/* Adjust the styles as needed */
/* .home-section,
.aboutus-section,
.aboutus1-section,
.aboutus2-section,
.aboutus3-section,
.contactus-section,
.footer-section {
  flex: 0 0 100vw;
  height: 100vh;
  scroll-snap-align: start;
} */
html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
}

.container {
  width: 700vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.mob-container{
  width:100%;
  height: auto;
}
/* .firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: yellow;
} */
/* .section-0{
  position: relative;
}
.section-1{
position: relative;
}
.section-2{
position: relative;
}
.section-3{
  position: relative;
} */
.lastContainer {
  display: flex;
  height: 100vh;
  background: yellow;
}

.panel {
  width: 100vw;
  height: 100vh;
}
.purple{
  position: relative;
}