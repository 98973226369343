.Home {
  background-color: #f5f1ee;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 0.3fr 1fr 0.3fr;
  padding: 50px 80px;

}
.Home-top {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.logo {
  width: 253px;
  height: 55px;
}
.Home-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.Home-center-AAKAASH {
  color: #3d3d3d;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.65em;
  text-align: center;
  z-index: 2;
}
.Home-center-BIG {
  color: #3d3d3d;
  font-family: 'Noto Serif Display', serif;
  font-size: 110px;
  font-weight: 600;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 1;
}
.hover-img{
  width: 100%;
  height: 100%;
}

/* .Home-center-BIG::before {
    position: absolute;
    content: attr(data-text);
    line-height: 80px;
    letter-spacing: 1px;
    top: 62px;
 left:30px;
 color: transparent;
 -webkit-text-stroke: 1px #000000; /* Stroke width and color for WebKit-based browsers */

/* } */

/* .Home-center-BIG::before { */
/* color: red;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
/* }  */
.circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightarrow {
  width: 40px;
  height: 40px;
}
.logoo {
  position: absolute;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(../Assets/Right.svg);
  background-repeat: no-repeat;
  background-size: contain;*/
  border-radius: 50%;
  border: 1px solid #3d3d3d;
}
.text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: rotatetext 10s linear infinite;
}
@keyframes rotatetext {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text span {
  position: absolute;
  left: 50%;
  top: -1px;

  font-size: 10px;
  transform-origin: 0 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
}
.circle-container {
  display: flex;
  align-items: end;
  justify-content: end;
}

@media(max-width:1000px){
  .Home {
    padding: 50px 20px;
  
  }
  .Home-center-BIG {
    font-size: 80px;
    line-height: 100px;
}
.hovered-image{
  width: 0;
  height: 0;
  position: absolute;
  z-index: -99;
  visibility: hidden;
}
}
@media(max-width:700px){
  .Home-center-AAKAASH {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}
  .Home-center-BIG {
    font-size: 60px;
    line-height: 80px;
}
}
@media(max-width:550px){
  .Home-center-AAKAASH {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
  .Home-center-BIG {
    font-size: 50px;
    line-height: 80px;
}
}
@media(max-width:460px){
  .logo {
    width: 200px;
    height: 50px;
  }
  .Home-center-AAKAASH {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
  .Home-center-BIG {
    font-size: 45px;
    line-height: 80px;
}
}
@media(max-width:430px){
  .logo {
    width: 180px;
    height: 48px;
  }
  .Home-center-AAKAASH {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}
  .Home-center-BIG {
    font-size: 36px;
    line-height: 80px;
}
}
