.Aboutus2 {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 50px 80px 0px 80px;
  position: absolute;
  overflow: hidden;
}
.aboutus2-container-1 {
  width: 100%;
  height: 100%;
  position: relative;
}
.year {
  font-family: "Unbounded", cursive;
  font-size: 9vw;
  font-weight: 700;
  line-height: 154px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d3d3d;
  position: absolute;
}

.aboutus2-container-2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  /* display: flex;
  justify-content: space-between; */
  align-items: end;
}
.aboutus2-container-2-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.aboutus2-textcontainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: left;
  padding-bottom: 50px;
}
.scroll-img-logo{
  width: 120px;
  padding-bottom: 40px;
}
.smallheading {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #3d3d3d;
}
.aboutus2-text {
  max-width: 626px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.aboutus-2-img-container{
  width: 100%;
  height: 100%;
}
.aboutpic3{
    width: 100%;
    height: 100%; 
    object-fit: cover;
}
@media (max-width: 1000px) {
  .Aboutus2 {
    background-color: transparent;
    width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 0.3fr 1fr;
    padding: 0px 20px 0px 20px;
    position: absolute;
  }
  .aboutus2-container-1{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .year{
    text-align: center;
  }
  .aboutus2-text {
  }
  .aboutus2-textcontainer {
    justify-content: center;
  }
}
@media (max-width: 700px) {
  .aboutus2-container-2 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 0.8fr;
}

}

@media (max-width: 610px){
  .smallheading {
    font-size: 21px;
  }
}
@media (max-width: 550px){
  .smallheading {
    font-size: 19px;
  }
  .aboutus2-text {
    font-size: 15px;
  }
}
@media (max-width: 495px){
  .year {
    font-size:8vw;
  }
  .smallheading {
    font-size: 17px;
  }
  .aboutus2-text {
    font-size: 13px;
  }
}
@media(max-width:445px){
  .smallheading {
    font-size: 16px;
  }
  .aboutus2-text {
    font-size: 12px;
  }
}