.Aboutus {
  background-color: transparent;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  padding: 0px 80px;
  overflow: hidden;
}
.about-1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 0.5fr 1fr;
}
.about-1-img {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
}
.about-round-text {
  width: 100%;
  height: 100%;
}
.about-pic-1 {
 width: 100%;
 height: 100%;
}
.aboutpic1 {
  width: 70%;
  height: 100%;
  object-fit:cover;
}

.about-text {
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
  gap: 30px;
}
.about-heading {
  font-family: 'Noto Serif Display', serif;
  font-size: 80px;
  font-weight: 600;
  color: #3d3d3d;
}
.about-text-content {
  max-width: 626px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3d3d3d;
}
.about-2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.aboutpic2 {
  width: 415px;
  height: 508px;
}

/* circle */
.circle1 {
  position: relative;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  border: 1px solid #3d3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoo1 {
  position: absolute;
  width: 68px;
  height: 68px;
  border: 1px solid #3d3d3d;
  border-radius: 50%;
}
.text1 {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotatetext 10s linear infinite;
}
@keyframes rotatetext {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text1 span {
  position: absolute;
  top: 5px;
  left: 50%;
  font-size: 12px;
  transform-origin: 0 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d3d3d;
}
.circle-container1 {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1537px) {
  .Aboutus {
    gap: 20px;
  }
  .about-pic-1 {
    width: 100%;
    height: 80%;
  }
  .aboutpic1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .aboutpic2 {
    width: 100%;
    object-fit: cover;
  }
}
@media (min-height: 864px) {
  .aboutpic2 {
    height: 70%;
    object-fit: cover;
  }
}
@media (max-width: 1200px) {
  .about-1-img {
    gap: 20px;
  }
  .Aboutus {
    gap: 20px;
  }
}
@media (max-width: 1150px) {
  .about-pic-1 {
    width: 380px;
    height: 280px;
  }
  .aboutpic1 {
    width: 380px;
    height: 280px;
  }
  .aboutpic2 {
    width: 380px;
    height: 480px;
    object-fit: cover;
  }
}
@media (max-width: 1100px) {
  .about-pic-1 {
    width: 350px;
    height: 250px;
  }
  .aboutpic1 {
    width: 350px;
    height: 250px;
  }
  .aboutpic2 {
    width: 350px;
    height: 480px;
    object-fit: cover;
  }
}
@media (max-width: 1000px) {
  .Aboutus {
    height: auto;
    grid-template-columns: 1fr;
    padding: 20px;

  }
  .about-text{
    padding: 50px 20px;
  }
  .Aboutus {
    gap: 40px;
}
  .about-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .about-1-img {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .about-round-text {
    width: 0;
    height: 0;
    display: none;
    visibility: hidden;
  }
  .about-pic-1 {
    width: 415px;
    height: 328px;
  }
  .aboutpic1 {
    width:100%;
    height: 100%;
    object-fit: cover;
  }
}
.about-2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: last baseline;
}
@media (max-width: 700px) {
  .about-heading {
    font-size: 60px;
  }
  
}
@media (max-width: 550px) {
  .about-heading {
    font-size: 50px;
  }
  .about-text-content {
    font-size: 16px;
  }
  .about-pic-1 {
    width: 350px;
    height: 280px;
  }
  .aboutpic2 {
    width: 300px;
    height: 400px;
    object-fit: cover;
  }
}
@media (max-width: 460px) {
  .about-heading {
    font-size: 45px;
  }
  .about-pic-1 {
    width: 300px;
    height: 250px;
  }
  .aboutpic2 {
    width: 250px;
    height: 330px;
    object-fit: cover;
  }
}
@media (max-width: 360px) {
  .about-heading {
    font-size: 36px;
  }
  .about-text-content {
    font-size: 14px;
  }
}