.Aboutus3 {
  background-color: #f5f1ee;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows:  1fr;
  padding: 50px 80px;
  overflow: hidden;
}
.aboutus3-container-1 {
  width: 100%;
  height: 100%;
}

.aboutus3-heading-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.aboutus-3-heading {
  font-family: "Noto Serif Display", serif;
  font-size: 80px;
  font-weight: 600;
  color: #3d3d3d;
}
.aboutus3-container-2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-arrow .slick-prev {
  visibility: hidden;
}
.slick-arrow .slick-next {
  visibility: hidden;
}
.supportingjobs-coursel-container {
  padding: 30px;
}
.supportingjobs-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d3d3d;
}
.supportingjobs-content {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d3d3d;
}
@media (max-width: 1000px) {
  .Aboutus3 {
    padding: 50px 20px;
  }
}
@media (max-width: 700px) {
  .aboutus-3-heading {
    font-size: 60px;
  }
}
@media (max-width: 550px) {
  .aboutus-3-heading {
    font-size: 50px;
  }
}

@media (max-width: 460px) {
  .aboutus-3-heading {
    font-size: 45px;
  }
}

@media (max-width: 360px) {
  .aboutus-3-heading {
    font-size: 36px;
  }
  .supportingjobs-content{
    font-size: 14px;
  }
}
